import React from 'react';
import PropTypes from 'prop-types';

const ListingHeader = ({ headline, headlineTag = 'h2', description }) => {
  const HeadlineTag = headlineTag;
  return headline?.length > 0 || description?.length > 0 ? (
    <div className="site--listing-header site--preset-header">
      {headline && (
        <HeadlineTag className="site--listing-header-title">
          {headline}
        </HeadlineTag>
      )}
      {description && (
        <div className="site--listing-header-description">{description}</div>
      )}
    </div>
  ) : (
    <></>
  );
};

ListingHeader.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
};
export default ListingHeader;
