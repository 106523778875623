import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import {
  FormattedMessage,
  useIntl,
  injectIntl,
  defineMessages,
} from 'react-intl';
import {
  CheckboxWidget,
  ObjectListWidget,
  TextWidget,
  ObjectBrowserWidget,
  SelectWidget,
} from '@plone/volto/components';

import { ColorListWidget, AlignWidget } from '@package/components/Widgets';

const messages = defineMessages({
  header_align: {
    id: 'Header alignment',
    defaultMessage: 'Header alignment',
  },
  linkTitle: {
    id: 'linkTitle',
    defaultMessage: 'Link title',
  },
  linkTitleDescription: {
    id: 'linkTitleDescription',
    defaultMessage:
      'If no title is entered, and a link is selected, the link will be added to the block title.',
  },
  linkMore: {
    id: 'linkMore',
    defaultMessage: 'Link more',
  },
  linkMoreTitle: {
    id: 'linkMoreTitle',
    defaultMessage: 'Title of link more',
  },
  backgroundColor: {
    id: 'backgroundColor',
    defaultMessage: 'Background color',
  },
  transparent: {
    id: 'transparent',
    defaultMessage: 'Transparent',
  },
  primaryLightColor: {
    id: 'primaryLightColor',
    defaultMessage: 'Primary light',
  },
  secondaryColor: {
    id: 'secondaryColor',
    defaultMessage: 'Secondary',
  },
  primaryColor: {
    id: 'primaryColor',
    defaultMessage: 'Primary color',
  },
  black: {
    id: 'black',
    defaultMessage: 'Black',
  },
  fullWidth: {
    id: 'fullWidth',
    defaultMessage: 'Full width',
  },
  titleColor: {
    id: 'titleColor',
    defaultMessage: 'Title color',
  },
  textColor: {
    id: 'textColor',
    defaultMessage: 'Text color',
  },
  columnsNumber: { id: 'columnsNumber', defaultMessage: 'Number of columns' },
  noAdaptColumns: {
    id: 'noAdaptColumns',
    defaultMessage: 'Do not fit the columns to the available space',
  },
  column: {
    id: 'column',
    defaultMessage: 'Column',
  },
  addColumn: {
    id: 'addColumn',
    defaultMessage: 'Add column',
  },
  icon: {
    id: 'icon',
    defaultMessage: 'Icon',
  },
  iconSize: {
    id: 'iconSize',
    defaultMessage: 'Image size',
  },
  mobileStyles: {
    id: 'mobileStyles',
    defaultMessage: 'Center column heading on mobile',
  },
  imageDescription: {
    id: 'imageDescription',
    defaultMessage:
      'The image must be a PNG or SVG. The maximum recommended size for PNG is 200x200px.',
  },
  headerTextPosition: {
    id: 'headerTextPosition',
    defaultMessage: 'Header text position',
  },
  headerTextPositionOnRight: {
    id: 'headerTextPositionOnRight',
    defaultMessage: 'On right',
  },
  headerTextPositionOnBottom: {
    id: 'headerTextPositionOnBottom',
    defaultMessage: 'On bottom',
  },
  dividerPosition: {
    id: 'dividerPosition',
    defaultMessage: 'Divider position',
  },
  dividerPositionBeforeTitle: {
    id: 'dividerPositionBeforeTitle',
    defaultMessage: 'Before title',
  },
  dividerPositionAfterTitle: {
    id: 'dividerPositionAfterTitle',
    defaultMessage: 'After title',
  },
  dividerPositionBeforeHeaderText: {
    id: 'dividerPositionBeforeHeaderText',
    defaultMessage: 'Before header text',
  },
  dividerPositionHide: {
    id: 'dividerPositionHide',
    defaultMessage: 'Hide divider',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, onChangeBlock, block } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="IconsAndTextBlock"
            defaultMessage="Icons and text block"
          />
          :
        </h2>
      </header>
      <Segment className="form">
        <ColorListWidget
          id="bg_color"
          title={intl.formatMessage(messages.backgroundColor)}
          colors={[
            {
              name: 'transparent',
              label: intl.formatMessage(messages.transparent),
            },
            {
              name: 'primary-light',
              label: intl.formatMessage(messages.primaryLightColor),
            },
            {
              name: 'secondary',
              label: intl.formatMessage(messages.secondaryColor),
            },
          ]}
          value={data.bg_color ?? 'transparent'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <CheckboxWidget
          id="fullWidth"
          title={intl.formatMessage(messages.fullWidth)}
          value={data.fullWidth ? data.fullWidth : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />

        <ColorListWidget
          id="title_color"
          title={intl.formatMessage(messages.titleColor)}
          colors={[
            {
              name: 'primary',
              label: intl.formatMessage(messages.primaryColor),
            },
            { name: 'black', label: intl.formatMessage(messages.black) },
          ]}
          value={data.title_color ?? 'primary'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />

        <ColorListWidget
          id="text_color"
          title={intl.formatMessage(messages.textColor)}
          colors={[
            {
              name: 'primary',
              label: intl.formatMessage(messages.primaryColor),
            },
            { name: 'black', label: intl.formatMessage(messages.black) },
          ]}
          value={data.text_color ?? 'black'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />

        <AlignWidget
          id="header_align"
          title={intl.formatMessage(messages.header_align)}
          alignments={['left', 'center']}
          value={data.header_align ? data.header_align : 'center'}
          fieldSet="default"
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>

      <Segment className="form">
        <SelectWidget
          id="n_columns"
          title={intl.formatMessage(messages.columnsNumber)}
          choices={[
            ['2', '2'],
            ['3', '3'],
            ['4', '4'],
          ]}
          value={data.n_columns}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <CheckboxWidget
          id="noAdaptColumns"
          title={intl.formatMessage(messages.noAdaptColumns)}
          value={data.noAdaptColumns ? data.noAdaptColumns : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
      <Segment className="form">
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: intl.formatMessage(messages.column),
              addMessage: intl.formatMessage(messages.addColumn),
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: [
                    'iconImage',
                    'iconSize',
                    'headerTextPosition',
                    'dividerPosition',
                    'href',
                    'href_title',
                  ],
                },
              ],
              properties: {
                iconImage: {
                  title: intl.formatMessage(messages.icon),
                  description: intl.formatMessage(messages.imageDescription),
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                iconSize: {
                  title: intl.formatMessage(messages.iconSize),
                  type: 'choices',
                  choices: [
                    ['s', 'Small'],
                    ['m', 'Medium'],
                    ['l', 'Large'],
                  ],
                  noValueOption: false,
                },
                headerTextPosition: {
                  title: intl.formatMessage(messages.headerTextPosition),
                  type: 'choices',
                  choices: [
                    [
                      'right',
                      intl.formatMessage(messages.headerTextPositionOnRight),
                    ],
                    [
                      'bottom',
                      intl.formatMessage(messages.headerTextPositionOnBottom),
                    ],
                  ],
                },
                dividerPosition: {
                  title: intl.formatMessage(messages.dividerPosition),
                  type: 'choices',
                  choices: [
                    [
                      'before_title',
                      intl.formatMessage(messages.dividerPositionBeforeTitle),
                    ],
                    [
                      'after_title',
                      intl.formatMessage(messages.dividerPositionAfterTitle),
                    ],
                    [
                      'before_header_text',
                      intl.formatMessage(
                        messages.dividerPositionBeforeHeaderText,
                      ),
                    ],
                    [
                      'no_divider',
                      intl.formatMessage(messages.dividerPositionHide),
                    ],
                  ],

                  noValueOption: false,
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                },
                href_title: {
                  title: intl.formatMessage(messages.linkTitle),
                  description: intl.formatMessage(
                    messages.linkTitleDescription,
                  ),
                },
              },

              required: [],
            };

            return columnsSchema;
          }}
        />

        <ObjectBrowserWidget
          id="href"
          title={intl.formatMessage(messages.linkMore)}
          mode="link"
          allowExternals={true}
          value={data.href}
          widgetOptions={{
            pattern_options: { maximumSelectionSize: 1 },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="href_title"
          title={intl.formatMessage(messages.linkMoreTitle)}
          value={data.href_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
      <Segment>
        <label htmlFor="mobileStyles">Mobile Appearance</label>

        <CheckboxWidget
          id="mobileStyles"
          title={intl.formatMessage(messages.mobileStyles)}
          value={data.mobileStyles ? data.mobileStyles : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
