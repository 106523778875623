export const contentFolderHasItems = (content, folder_name) => {
  const has_items =
    content?.items.some((e) => e.id === folder_name) &&
    content?.items.filter((i) => i.id === folder_name)?.[0]?.has_children;
  return has_items;
};

export const checkHasContent = (content) => {
  if (content) {
    let blocks = content.blocks.filter((block) => block?.text !== '');
    return blocks.length > 0 ? true : false;
  }
};
