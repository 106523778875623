import React from 'react';
import cx from 'classnames';
import redraft from 'redraft';
import { ListingLinkMore } from '@package/components';
import ViewColumn from './Block/ViewBlock';
import { Container } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { checkHasContent } from '@package/helpers';

const View = ({ data }) => {
  const content = (
    <>
      {(data.title || checkHasContent(data.description)) && (
        <div className="block-content-header">
          {data.title && <div className="title">{data.title}</div>}
          {checkHasContent(data.description) && (
            <div className="description">
              {redraft(
                data.description,
                config.settings.richtextViewSettings.ToHTMLRenderers,
                config.settings.richtextViewSettings.ToHTMLOptions,
              )}
            </div>
          )}
        </div>
      )}

      <div className="columns-wrapper">
        {data?.columns?.map((numberBlock, i) => (
          <ViewColumn data={numberBlock} index={i} />
        ))}
      </div>

      <ListingLinkMore
        linkTitle={data.href_title}
        linkHref={data.href}
        buttonClassName="site--button-primary"
      />
    </>
  );
  return (
    <div
      className={cx('block-image-columns site--preset-wrapper ', {
        ['columns-' + [data.n_columns ?? 4]]: data.n_columns ?? 4,
        'multi-rows': data.columns?.length > data.n_columns ?? 4,
        'full-width': data.fullWidth,
        ['site--preset-bg-' + data.bg_color]: data.bg_color !== 'transparent',
      })}
    >
      {data.fullWidth ? <Container>{content}</Container> : content}
    </div>
  );
};

export default View;
