import React from 'react';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import {
  Button,
  ComboBox,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Text,
  FieldError,
} from 'react-aria-components';
import CaretDown from '@package/icons/caret-down.svg';

const MySelect = ({
  classNameWrapper,
  label,
  placeholder,
  description,
  errorMessage,
  options,
  children,
  onChange,
  value,
  ...props
}) => {
  return (
    <div className={cx('site-select-widget', classNameWrapper)}>
      <ComboBox
        {...props}
        onSelectionChange={(v) => onChange(v)}
        aria-label={label ? null : placeholder}
        //inputValue={value}
        selectedKey={value}
        defaultItems={options}
      >
        {label && <Label>{label}</Label>}
        <div className="my-combobox-container">
          <Input
            placeholder={placeholder}
            aria-controls={props['aria-controls']}
            aria-label={label ? null : placeholder}
          />
          <Button>
            <Icon name={CaretDown} />
          </Button>
        </div>
        {description && <Text slot="description">{description}</Text>}
        <FieldError>{errorMessage}</FieldError>
        <Popover>
          <ListBox selectedKeys={typeof value == 'string' ? [value] : value}>
            {options?.map((opt, i) => (
              <ListBoxItem id={opt.value} key={i}>
                {opt.label}
              </ListBoxItem>
            ))}
            {children}
          </ListBox>
        </Popover>
      </ComboBox>
    </div>
  );
};

export default MySelect;
