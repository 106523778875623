/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React from 'react';

import CustomFooter from '@package/components/layout/Footer/Footer';

const Footer = ({ pathname }) => {
  return <CustomFooter pathname={pathname} />;
};

export default Footer;
