import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Select } from '@package/components/Widgets';
import { Container, Pagination } from 'semantic-ui-react';
import paginationLeftSVG from '@plone/volto/icons/left-key.svg';
import paginationRightSVG from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';
import config from '@plone/volto/registry';
const messages = defineMessages({
  sort_on: { id: 'search_prj_sort', defaultMessage: 'Ordina per' },
});

const List = ({ items, filters, setFilters, results_region_id, visible }) => {
  const intl = useIntl();
  const handleQueryPaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    setFilters({ ...filters, page: activePage });
  };

  return visible ? (
    <div className="list-wrapper">
      <Container>
        <div className="widgets">
          <Select
            classNameWrapper="widget"
            placeholder={intl.formatMessage(messages.sort_on)}
            value={filters.sort_on}
            options={[
              { label: 'Bando', value: 'bando' },
              { label: 'Ente/organizzazione', value: 'ente' },
              { label: 'Budget progetto', value: 'budget' },
              { label: 'Contributo Fondazione Cariplo', value: 'contributo' },
              { label: 'Data di avvio', value: 'start_date' },
            ]}
            onChange={(v) => {
              setFilters({ ...filters, sort_on: v });
            }}
            aria-controls={results_region_id}
          />
        </div>
        <div className="list-items">lista dei progetti</div>
        <div className="list-footer">
          <Pagination
            activePage={filters.page}
            totalPages={Math.ceil(
              items.length / config.settings.defaultPageSize,
            )}
            onPageChange={handleQueryPaginationChange}
            firstItem={null}
            lastItem={null}
            prevItem={{
              content: <Icon name={paginationLeftSVG} size="18px" />,
              icon: true,
              'aria-disabled': filters.page === 1,
              className: filters.page === 1 ? 'disabled' : null,
            }}
            nextItem={{
              content: <Icon name={paginationRightSVG} size="18px" />,
              icon: true,
              // 'aria-disabled': !this.props.search.batching.next,
              // className: !this.props.search.batching.next ? 'disabled' : null,
            }}
          />
        </div>
      </Container>
    </div>
  ) : (
    <></>
  );
};

export default List;
