import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import redraft from 'redraft';
import { Grid, Image } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { checkHasContent } from '@package/helpers';

import { PresetWrapper, ShareButtons, Button } from '@package/components';
import config from '@plone/volto/registry';

const messages = defineMessages({
  cta_title_default: {
    id: 'cta_title_default',
    defaultMessage: 'Go to content',
  },
});

const View = (props) => {
  const intl = useIntl();
  const { data } = props;

  const img_column_width = data.img_column_width
    ? parseInt(data.img_column_width)
    : 6;

  return (
    <PresetWrapper
      {...props.data}
      usePresetDefaults={false}
      className="block-text7"
    >
      {data.share_social && (
        <div className="content-social">
          <ShareButtons showLabel={false} />
        </div>
      )}
      <Grid className="block-content" verticalAlign="middle">
        <Grid.Row
          columns={2}
          className={cx({
            'block-right': data.right,
          })}
        >
          <Grid.Column computer={img_column_width} tablet={12} mobile={12}>
            {data.url && (
              <Image
                src={`${flattenToAppURL(props.data.url)}/@@images/image/larger`}
                className="block-text7-image"
                aria-hidden="true"
              />
            )}
          </Grid.Column>
          <Grid.Column computer={12 - img_column_width} tablet={12} mobile={12}>
            {(checkHasContent(data.title) ||
              checkHasContent(data.content) ||
              data.has_cta) && (
              <div className="block-text7-body">
                {checkHasContent(data.title) && (
                  <h3 className="title">
                    {redraft(
                      data.title,
                      config.settings.richtextViewSettings.ToHTMLRenderers,
                      config.settings.richtextViewSettings.ToHTMLOptions,
                    )}
                  </h3>
                )}
                {checkHasContent(data.content) && (
                  <div className="content">
                    {redraft(
                      data.content,
                      config.settings.richtextViewSettings.ToHTMLRenderers,
                      config.settings.richtextViewSettings.ToHTMLOptions,
                    )}
                  </div>
                )}
                {data.has_cta && (
                  <div className="buttonBottom">
                    <Button
                      as={UniversalLink}
                      size="large"
                      href={
                        data.link_to
                          ? flattenToAppURL(data.link_to[0]?.['@id'])
                          : data.link_to_external
                          ? data.link_to_external
                          : null
                      }
                      arrow={true}
                    >
                      {data.cta_title ||
                        intl.formatMessage(messages.cta_title_default)}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PresetWrapper>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
