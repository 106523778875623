import React, { useState, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';

import mapPinSVG from '@package/icons/map-pin.svg';
import listSVG from '@package/icons/list.svg';

import SearchForm from './SearchForm';
import Map from './Map';
import List from './List';

const messages = defineMessages({
  title: {
    id: 'search-prj-title',
    defaultMessage: 'I progetti',
  },
  description: {
    id: 'search-prj-description',
    defaultMessage:
      "Di seguito sono riportati tutti i progetti sull'agricolutra finanziati da Fondazione Cariplo dal 2004 ad oggi.",
  },
  description_filters: {
    id: 'search-prj-description_filters',
    defaultMessage: 'Usa i filtri per affinare la ricerca.',
  },
  results_found: {
    id: 'search-prj-results_found',
    defaultMessage: '{found} progetti trovati su {total}',
  },
  tablist_label: {
    id: 'search-prj-tablist',
    defaultMessage: 'Visualizza i risultati come',
  },
  map: {
    id: 'search-prj-map',
    defaultMessage: 'Mappa',
  },
  list: {
    id: 'search-prj-list',
    defaultMessage: 'Lista dei progetti',
  },
});
const Body = ({ isEditMode, id }) => {
  const intl = useIntl();
  const results_region_id = id + 'src-prj-result';
  const [filters, setFilters] = useState({ page: 1, sortOn: 'bando' });
  const [view, setView] = useState('map');
  useEffect(() => {
    console.log('change filters', filters);
  }, [filters]);

  const doSearch = () => {};

  return (
    <div className="search-prj-wrapper">
      <div className="search-prj-header">
        <h3>{intl.formatMessage(messages.title)}</h3>
        <div className="description">
          {intl.formatMessage(messages.description)}{' '}
          <strong>{intl.formatMessage(messages.description_filters)}</strong>
        </div>
      </div>

      <SearchForm
        filters={filters}
        setFilters={setFilters}
        results_region_id={results_region_id}
      />

      {/*----- RISULTATI ----- */}
      <div className="serach-prj-results full-width" id={results_region_id}>
        <div className="serach-prj-results-header">
          <Container>
            <div className="serach-prj-results-header-content">
              <div className="left">
                <div
                  role="tablist"
                  aria-label={intl.formatMessage(messages.tablist_label)}
                >
                  <button
                    id="tab-map"
                    type="button"
                    role="tab"
                    aria-selected={view === 'map'}
                    aria-controls={results_region_id + '_map'}
                    tabindex="-1"
                    onClick={() => {
                      setView('map');
                    }}
                  >
                    <Icon name={mapPinSVG} />{' '}
                    <span>{intl.formatMessage(messages.map)}</span>
                  </button>
                  <button
                    id="tab-list"
                    type="button"
                    role="tab"
                    aria-selected={view === 'list'}
                    aria-controls={results_region_id + '_list'}
                    tabindex="-1"
                    onClick={() => {
                      setView('list');
                    }}
                  >
                    <Icon name={listSVG} />{' '}
                    <span>{intl.formatMessage(messages.list)}</span>
                  </button>
                </div>
              </div>
              <div className="right">
                {intl.formatMessage(messages.results_found, {
                  found: 57,
                  total: 271,
                })}
              </div>
            </div>
          </Container>
        </div>
        <div
          id={results_region_id + '_map'}
          role="tabpanel"
          tabindex="0"
          aria-labelledby="tab-map"
          className={view !== 'map' ? 'hidden' : ''}
        >
          <Map
            items={[
              {
                title: 'prova',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
            ]}
          />
        </div>
        <div
          id={results_region_id + '_list'}
          role="tabpanel"
          tabindex="0"
          aria-labelledby="tab-list"
          className={view !== 'list' ? 'hidden' : ''}
        >
          <List
            visible={view === 'list'}
            filters={filters}
            setFilters={setFilters}
            results_region_id={results_region_id}
            items={[
              {
                title: 'prova',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
              {
                title: 'prova 1',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
              {
                title: 'prova 2',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
              {
                title: 'prova 3',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
              {
                title: 'prova4 ',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
              {
                title: 'prova5',
                lat_progetto: 12,
                lon_progetto: 12,
                bando: 'Gestione sostenibile delle acque (2010)',
                budget_progetto_reale: '3.133.405 €',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Body;
