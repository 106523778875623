import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/schemas/utils';
import { addPresetFields } from '@package/helpers/presets';

const messages = defineMessages({
  title_color: {
    id: 'title_color',
    defaultMessage: 'Title color',
  },
  show_icon: {
    id: 'show_icon',
    defaultMessage: 'Show icon',
  },
  show_bullet_list: {
    id: 'show_bullet_list',
    defaultMessage: 'Show bulleted list',
  },
  show_date: {
    id: 'show_date',
    defaultMessage: 'Show date',
  },
});

const getDownloadVariationSchema = (schema, formData, intl, pos = 0) => {
  addSchemaField(
    schema,
    'show_icon',
    intl.formatMessage(messages.show_icon),
    null,
    { type: 'boolean', default: true },
    pos,
    'default',
  );
  pos++;

  addSchemaField(
    schema,
    'show_bullet_list',
    intl.formatMessage(messages.show_bullet_list),
    null,
    { type: 'boolean' },
    pos,
    'default',
  );
  pos++;

  addSchemaField(
    schema,
    'show_date',
    intl.formatMessage(messages.show_date),
    null,
    { type: 'boolean' },
    pos,
    'default',
  );
  pos++;

  pos = addPresetFields(schema, intl, pos, 'items', {
    title_color: { colors: ['primary', 'black'] },
  });

  return pos;
};

export default getDownloadVariationSchema;
