export const GET_SEARCH_PRJ_FILTERS = 'GET_SEARCH_PRJ_FILTERS';

export function getSearchPrjFilters(params) {
  return {
    type: GET_SEARCH_PRJ_FILTERS,
    request: {
      op: 'get',
      path: `/@search-project-filters`,
      params: params,
    },
  };
}
