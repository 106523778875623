import loadable from '@loadable/component';

export const ProgettoView = loadable(() =>
  import(
    /* webpackChunkName: "ISProgettoView" */ '@package/components/Views/Progetto/ProgettoView'
  ),
);
export const ProgettoDettagli = loadable(() =>
  import(
    /* webpackChunkName: "ISProgettoView" */ '@package/components/Views/Progetto/ProgettoDettagli'
  ),
);
export const ProgettoObiettivoPO = loadable(() =>
  import(
    /* webpackChunkName: "ISProgettoView" */ '@package/components/Views/Progetto/ProgettoObiettivoPO'
  ),
);
export const ProgettoMappa = loadable(() =>
  import(
    /* webpackChunkName: "ISProgettoView" */ '@package/components/Views/Progetto/ProgettoMappa'
  ),
);
export const ProgettoAllegati = loadable(() =>
  import(
    /* webpackChunkName: "ISProgettoView" */ '@package/components/Views/Progetto/ProgettoAllegati'
  ),
);
export const ProgettoGalleria = loadable(() =>
  import(
    /* webpackChunkName: "ISProgettoView" */ '@package/components/Views/Progetto/ProgettoGalleria'
  ),
);
