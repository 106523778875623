import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { DateRangeFacet } from '@plone/volto/components/manage/Blocks/Search/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import zoomSVG from '@plone/volto/icons/zoom.svg';

import { Select } from '@package/components/Widgets';
import { getSearchPrjFilters } from '@package/actions';

const messages = defineMessages({
  filter_bando: {
    id: 'search-prj-filter_bando',
    defaultMessage: 'Seleziona un bando',
  },
  filter_n_pratica: {
    id: 'search-prj-filter_n_pratica',
    defaultMessage: 'Numero pratica',
  },
  filter_indirizzo_completo: {
    id: 'search-prj-filter_indirizzo_completo',
    defaultMessage: 'Indirizzo completo',
  },
  filter_comune: {
    id: 'search-prj-filter_comune',
    defaultMessage: 'Comune',
  },
  filter_provincia: {
    id: 'search-prj-filter_provincia',
    defaultMessage: 'Provincia',
  },
  filter_veste_giuridica: {
    id: 'search-prj-filter_veste_giuridica',
    defaultMessage: 'Veste giuridica organizzazione',
  },
  filter_organizzazione: {
    id: 'search-prj-filter_organizzazione',
    defaultMessage: 'Organizzazione',
  },
  filter_contributo: {
    id: 'search-prj-filter_contributo',
    defaultMessage: 'Contributo Fondazione Cariplo',
  },
  filter_partner: {
    id: 'search-prj-filter_partner',
    defaultMessage: 'Partner',
  },
  filter_date: {
    id: 'search-prj-filter_date',
    defaultMessage: 'Data',
  },
  reset_filters: {
    id: 'search-prj-reset_filters',
    defaultMessage: 'Reset filtri',
  },
  in_progress: {
    id: 'search-prj-filter_in_progress',
    defaultMessage: 'Progetti in corso',
  },
  search: {
    id: 'search-prj-search',
    defaultMessage: 'Cerca progetti',
  },
});
const SearchForm = ({ filters, setFilters, results_region_id }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const searchFiltersStatus = useSelector(
    (state) => state.searchProjectFilters,
  );
  const searchFilters = useSelector(
    (state) => state.searchProjectFilters.result,
  );
  useEffect(() => {
    if (
      !searchFilters &&
      !searchFiltersStatus.loading &&
      !searchFiltersStatus.loaded
    ) {
      dispatch(getSearchPrjFilters());
    }
  }, []);

  const SelectFilter = ({ items, placeholder, field }) =>
    items?.length > 0 ? (
      <Select
        classNameWrapper="widget"
        placeholder={placeholder}
        value={filters[field]}
        options={items
          .filter((t) => t?.length > 0)
          .map((t) => {
            return { label: t, value: t };
          })}
        onChange={(v) => {
          setFilters({ ...filters, [field]: v });
        }}
        aria-controls={results_region_id}
      />
    ) : (
      <></>
    );

  return searchFilters ? (
    <div className="search-prj-filters">
      <div className="search-prj-filters-options">
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_bando)}
          items={searchFilters.titolo_bando}
          field="titolo_bando"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_n_pratica)}
          items={searchFilters.numero_pratica}
          field="numero_pratica"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_indirizzo_completo)}
          items={searchFilters.sede_ente_capofila}
          field="sede_ente_capofila"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_comune)}
          items={searchFilters.localizzazione}
          field="localizzazione"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_provincia)}
          items={searchFilters.provincia}
          field="provincia"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_organizzazione)}
          items={searchFilters.organizzazione_po}
          field="organizzazione_po"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_veste_giuridica)}
          items={searchFilters.veste_giuridica}
          field="veste_giuridica"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_contributo)}
          items={searchFilters.contributo_erogato_fc}
          field="contributo_erogato_fc"
        />
        <SelectFilter
          placeholder={intl.formatMessage(messages.filter_partner)}
          items={searchFilters.partenariato}
          field="partenariato"
        />
        <div className="widget">
          <DateRangeFacet
            facet={{
              '@id': 'start-end-date',
              title: intl.formatMessage(messages.filter_date),
              field: { value: 'date' },
            }}
            value={filters.date}
            onChange={(f, v) => {
              setFilters({ ...filters, date: v });
            }}
            aria-controls={results_region_id}
          />
        </div>
      </div>
      <div className="search-prj-filters-actions">
        <div className="left">
          <button
            onClick={() => {
              setFilters({});
            }}
            size="mini"
            className="clear-filters"
            aria-controls={results_region_id}
          >
            {intl.formatMessage(messages.reset_filters)}
            <FontAwesomeIcon icon={['far', 'trash-alt']} className="icon" />
          </button>
        </div>
        <div className="right">
          <Checkbox
            toggle
            label={intl.formatMessage(messages.in_progress)}
            id="in_progress"
            onChange={(d, data) => {
              setFilters({ ...filters, in_progress: data.checked });
            }}
            checked={filters.in_progress ?? false}
            aria-controls={results_region_id}
          />
          <Button
            onClick={() => {
              doSearch();
            }}
            primary
            className="submit-button"
            aria-controls={results_region_id}
          >
            {intl.formatMessage(messages.search)}
            <Icon name={zoomSVG} />
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SearchForm;
