import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import {
  FormattedMessage,
  useIntl,
  injectIntl,
  defineMessages,
} from 'react-intl';
import { ColorListWidget } from '@package/components/Widgets';
import { CheckboxWidget } from '@plone/volto/components';
import {
  //CheckboxWidget,
  ObjectListWidget,
  TextWidget,
  ObjectBrowserWidget,
  SelectWidget,
} from '@plone/volto/components';

const messages = defineMessages({
  columnsNumber: { id: 'columnsNumber', defaultMessage: 'Number of columns' },
  columns: {
    id: 'columns',
    defaultMessage:
      'If created columns number is less then selected number, columns will adapt to the available width',
  },
  backgroundColor: {
    id: 'backgroundColor',
    defaultMessage: 'Background color',
  },
  fullWidth: {
    id: 'fullWidth',
    defaultMessage: 'Full width',
  },
  imageTitle: {
    id: 'imageTitle',
    defaultMessage: 'Title',
  },
  imageDesc: {
    id: 'imageDesc',
    defaultMessage:
      'The image will be resized and fitted to the column width if larger than the available space. Otherwise, it will keep its proportions.',
  },
  column: {
    id: 'column',
    defaultMessage: 'Column',
  },
  addColumn: {
    id: 'addColumn',
    defaultMessage: 'Add column',
  },
  linkTitle: {
    id: 'linkTitle',
    defaultMessage: 'Link title',
  },
  linkMore: {
    id: 'linkMore',
    defaultMessage: 'Link more',
  },
  linkMoreTitle: {
    id: 'linkMoreTitle',
    defaultMessage: 'Title of link more',
  },
});

const Sidebar = (props) => {
  const { data, onChangeBlock, block } = props;
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="Image columns"
            defaultMessage="Side by side images"
          />
          :
        </h2>
      </header>
      <Segment className="form">
        <SelectWidget
          id="n_columns"
          title={intl.formatMessage(messages.columnsNumber)}
          description={intl.formatMessage(messages.columns)}
          choices={[
            ['3', '3'],
            ['4', '4'],
          ]}
          value={data.n_columns}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>
      <Segment className="form">
        <ColorListWidget
          id="bg_color"
          description={intl.formatMessage(messages.backgroundColor)}
          colors={[
            { name: 'outline-white', label: 'Transparent' },
            { name: 'primary-light', label: 'Primary light' },
          ]}
          value={data.bg_color ?? 'outline-white'}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <CheckboxWidget
          id="fullWidth"
          title={intl.formatMessage(messages.fullWidth)}
          value={data.fullWidth ?? false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
      </Segment>
      <Segment className="form">
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: intl.formatMessage(messages.column),
              addMessage: intl.formatMessage(messages.addColumn),
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['image', 'href'],
                },
              ],
              properties: {
                image: {
                  title: intl.formatMessage(messages.imageTitle),

                  description: intl.formatMessage(messages.imageDesc),
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                },
              },

              required: [],
            };

            return columnsSchema;
          }}
        />

        <ObjectBrowserWidget
          id="href"
          title={intl.formatMessage(messages.linkMore)}
          mode="link"
          allowExternals={true}
          value={data.href}
          widgetOptions={{
            pattern_options: { maximumSelectionSize: 1 },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="href_title"
          title={intl.formatMessage(messages.linkTitle)}
          value={data.href_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
