/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Logo, UniversalLink } from '@plone/volto/components';
import { SocialLinks } from 'volto-social-settings';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
// import Button from '@package/components/Button/Button';

import { getItemsByPath } from 'volto-dropdownmenu/utils';
import { displayBanner } from 'volto-gdpr-privacy';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
  cookieSettings: {
    id: 'Cookie settings',
    defaultMessage: 'Cookie settings',
  },
  privacyAndCookies: {
    id: 'Privacy and Cookie',
    defaultMessage: 'Privacy e Cookie',
  },
  contacts: {
    id: 'Contacts',
    defaultMessage: 'Contacts',
  },
  siteMap: {
    id: 'Site map',
    defaultMessage: 'Site map',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ pathanme }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const dropdownMenuNavItems = useSelector(
    (state) => state.dropdownMenuNavItems?.result,
  );

  let p = '/' + intl.locale;

  const menu = getItemsByPath(dropdownMenuNavItems, p).filter(
    (m) => !m.additionalClasses || m.additionalClasses?.indexOf('home') < 0,
  );

  return (
    <div id="footer">
      <Container>
        <div id="footer-main">
          <div className="footer-column">
            <div className="logo">
              <Logo />
            </div>
          </div>
          {menu.map((m, i) => {
            const link =
              m.mode === 'dropdown' ? m.showMoreLink?.[0] : m.linkUrl?.[0];
            return (
              <div className="footer-column" key={i}>
                <h4>
                  {link ? (
                    <UniversalLink item={link}>{m.title}</UniversalLink>
                  ) : (
                    m.title
                  )}
                </h4>
                {m.navigationRoot?.length > 0 && (
                  <ul>
                    {m.navigationRoot.map((nr, ii) => (
                      <li key={i + '-' + ii}>
                        <UniversalLink item={nr}>{nr.title}</UniversalLink>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}

          <div className="footer-column">
            <SocialLinks />
          </div>
        </div>
      </Container>

      <div id="footer-small">
        <Container>
          <div className="footer-small-wrapper">
            <button
              className="footer-gdpr-privacy-show-banner item"
              onClick={(e) => {
                dispatch(displayBanner(true, true));
              }}
              title={intl.formatMessage(messages.cookieSettings)}
            >
              {intl.formatMessage(messages.cookieSettings)}
            </button>

            <UniversalLink
              className="item"
              href={intl.locale === 'it' ? '/it/sitemap' : '/en/sitemap'}
            >
              {intl.formatMessage(messages.siteMap)}
            </UniversalLink>

            <UniversalLink
              className="item"
              href={
                intl.locale === 'it'
                  ? '/it/privacy-e-cookies'
                  : '/en/privacy-and-cookies'
              }
            >
              {intl.formatMessage(messages.privacyAndCookies)}
            </UniversalLink>

            {/* <UniversalLink
              className="item"
              href={intl.locale === 'it' ? '/it/contatti' : '/en/contacts'}
            >
              {intl.formatMessage(messages.contacts)}
            </UniversalLink> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default Footer;
