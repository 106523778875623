import React, { useState, useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import cx from 'classnames';

import { OSMMap } from 'volto-venue';
import { Icon, UniversalLink } from '@plone/volto/components';
import pinSVG from '@package/icons/pin.svg';
import { ListingImage } from '@package/components';
import { LeafIcon } from '@package/helpers';

const BANDI_COLORS = {
  'Gestione sostenibile delle acque': 'light-blue',
  'Tutelare e valorizzare la biodiversità': 'medium-green',
  'Tutelare la qualità delle acque': 'blue',
  'Connessione ecologica': 'light-green',
  'Capitale naturale': 'dark-green',
};
const OTHER_BANDI_COLOR = 'other';

const messages = defineMessages({
  ente: { id: 'prj_ente', defaultMessage: 'ENTE' },
  scheda_progetto: {
    id: 'prj_detail',
    defaultMessage: 'Scheda progetto',
  },
});

const Map = ({ items }) => {
  const intl = useIntl();
  const [markers, setMarkers] = useState([]);

  const getItemColor = (item) => {
    const k = Object.keys(BANDI_COLORS).filter(
      (c) => item.bando?.indexOf(c) >= 0,
    );

    if (k.length > 0) {
      return BANDI_COLORS[k[0]];
    }
    return OTHER_BANDI_COLOR;
  };
  useEffect(() => {
    let points = items
      .filter(
        (item) =>
          item.lat_progetto &&
          item.lon_progetto &&
          item.lat_progetto !== 0 &&
          item.lon_progetto !== 0,
      )
      .map((item) => {
        const itemColor = getItemColor(item);
        return {
          title: item.title,
          description: item.description ?? null,
          latitude: item.lat_progetto,
          longitude: item.lon_progetto,
          divIcon: LeafIcon(
            {
              className: 'bandi_color_' + itemColor,
            },
            item,
          ),
          popupContent: (
            <div>
              <div className="image-wrapper">
                <ListingImage item={item} show_default={true} />
                {item.budget_progetto_reale && (
                  <div className="budget">
                    <div className="budget-title">BUDGET</div>
                    <div className="budget-value">
                      {item.budget_progetto_reale}
                    </div>
                  </div>
                )}
              </div>
              <div className={cx('bando', 'bandi_bg_' + itemColor)}>
                {item.bando}
              </div>
              <div className="title">{item.title}</div>
              <div className="info">
                <div className="title">{intl.formatMessage(messages.ente)}</div>
                <div className="value">{item.title}</div>
              </div>
              <div className="detail">
                <UniversalLink className="ui button primary large" item={item}>
                  {intl.formatMessage(messages.scheda_progetto)}
                </UniversalLink>
              </div>
            </div>
          ),

          // onMarkerClick: (e) => {
          //   // se è un luogo ed ha una scadenza il link è nella popup
          //   if (!(item.end && item['@type'] === 'Venue')) {
          //     history.push(item['@id']);
          //   }
          // },
        };
      });

    setMarkers(points);
  }, [items, intl]);

  return (
    <div className="map-wrapper">
      {__CLIENT__ && markers.length > 0 && (
        <OSMMap
          markers={markers}
          showTooltip={true}
          showPopup={true}
          cluster={true}
          mapOptions={{
            scrollWheelZoom: false,
            // tap: false,
            // dragging: false,
          }}
        />
      )}
      <div className="legend">
        {Object.keys(BANDI_COLORS).map((k) => (
          <div className="legend-item" key={k}>
            <div className={cx('symbol', 'bandi_color_' + BANDI_COLORS[k])}>
              <Icon name={pinSVG} size={[20, 28]} />
            </div>
            <div className="label">{k}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Map;
