import DefaultView from '@plone/volto/components/theme/View/DefaultView';
import { ProgettoView } from '@package/components/Views/Progetto';

export const SiteViews = (config) => {
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    'News Item': DefaultView,
    Event: DefaultView,
    Progetto: ProgettoView,
  };
};
