import { defineMessages } from 'react-intl';
import { findIndex } from 'lodash';
import { addSchemaField } from '@package/config/Blocks/schemas/utils';
import { addPresetFields } from '@package/helpers/presets';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  header_align: {
    id: 'Header alignmnet',
    defaultMessage: 'Header alignment',
  },
  criteria: {
    id: 'Listing criteria',
    defaultMessage: 'Criteria',
  },
  elements_style: {
    id: 'Listing elements style',
    defaultMessage: 'Elements style',
  },
});

/** DEFAULT **/

const getDefaultSchema = (schema, formData, intl, position = 3) => {
  let pos = position;
  const defaultFieldsetIDX = findIndex(schema.fieldsets, { id: 'default' });
  schema.fieldsets[defaultFieldsetIDX].fields = schema.fieldsets[
    defaultFieldsetIDX
  ].fields.filter((f) => f !== 'querystring'); //move 'querystring' field to a separate fieldset

  schema.fieldsets.splice(1, 0, {
    fields: ['querystring'],
    id: 'criteria',
    title: intl.formatMessage(messages.criteria),
  });
  schema.fieldsets.splice(1, 0, {
    fields: [],
    id: 'items',
    title: intl.formatMessage(messages.elements_style),
  });

  addSchemaField(
    schema,
    'description',
    intl.formatMessage(messages.description),
    null,
    { type: 'string' },
    pos,
  );
  pos++;

  addSchemaField(
    schema,
    'header_align',
    intl.formatMessage(messages.header_align),
    null,
    {
      widget: 'sitealign',
      defaultValue: 'left',
      alignments: ['left', 'center'],
    },
    pos,
  );
  pos++;

  pos = addPresetFields(schema, intl, pos, 'default', {
    bg_color: {
      colors: ['transparent', 'secondary', 'primary-light'],
    },
  });

  return pos;
};

export default getDefaultSchema;
