import ReactDOMServer from 'react-dom/server';
import pinSVG from '@package/icons/pin.svg';
import { Icon } from '@plone/volto/components';

//  Create the Icon. Reuse it in all site maps
export const LeafIcon = (options, item, iconUrl, svg = pinSVG) => {
  const _options = {
    iconSize: [30, 41],
    iconAnchor: [12.5, 20.5],
    ...options,
  };
  return {
    html: ReactDOMServer.renderToString(
      // used from map
      iconUrl ? (
        <img
          src={iconUrl}
          alt={item.title}
          width={_options.iconSize[0]}
          height={_options.iconSize[1]}
        />
      ) : svg ? (
        <Icon
          name={svg}
          alt={item?.title ?? ''}
          size={_options.iconSize[1] + 'px'}
        />
      ) : (
        <></>
      ),
    ),

    ...options,
  };
};
