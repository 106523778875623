import React, { useEffect, useState } from 'react';
import cx from 'classnames';
//import { useSelector } from 'react-redux';
import { Container, Segment } from 'semantic-ui-react';

import {
  //Anontools,
  Logo,
  Navigation,
  LanguageSelector,
  //SearchWidget,
} from '@plone/volto/components';
import { SecondaryMenu } from 'volto-secondarymenu';
import HeaderSearch from './HeaderSearch';

const SiteHeader = ({ pathname }) => {
  //const token = useSelector((state) => state.userSession.token);
  const [sticky, setSticky] = useState(false);
  const handleSticky = () => {
    setSticky(window.scrollY > 64);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSticky, false);
    return () => {
      window.removeEventListener('scroll', handleSticky, false);
    };
  }, []);

  //style={sticky ? { width: width + 'px' } : {}}
  return (
    <div
      className={cx('header-wrapper', {
        'padding-bottom': sticky,
      })}
    >
      <div className="header-small">
        <Container>
          <div className="header-small-content-right">
            <SecondaryMenu pathname={pathname} />
            <LanguageSelector />
          </div>
        </Container>
      </div>
      <div className={sticky ? 'ui fixed top sticky' : null}>
        <Segment basic className="header-wrapper" role="banner">
          <Container>
            <div className="header">
              <div className="logo-nav-wrapper">
                <div className="logo">
                  <Logo />
                </div>
              </div>

              {/* INFO: Remove .align-right class to center the menu  */}
              <div className="nav-wrapper align-right">
                <Navigation pathname={pathname} />
              </div>

              <div className="nav-secondary-search-wrapper">
                <HeaderSearch />
              </div>

              {/* {!token && (
                <div className="tools">
                  <Anontools />
                </div>
              )} */}
              {/* <div className="search">
                <SearchWidget />
              </div> */}
            </div>
          </Container>
        </Segment>
      </div>
    </div>
  );
};

export default SiteHeader;
