/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import { config as faConfig, library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as IconsRegular from '@fortawesome/free-regular-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons';

import { SiteBlocks } from '@package/config/Blocks';
import { SiteWidgets } from '@package/config/Widgets';
import { SiteViews } from '@package/config/Views';
import applyRichTextConfig from '@package/config/RichTextEditor/config';
import applyAccordionConfig from '@package/config/Accordion/config';
import '@plone/volto/config';

faConfig.autoAddCss = false;
const iconList = Object.keys(Icons.fas).map((icon) => Icons[icon]);
const iconListRegular = Object.keys(IconsRegular.far).map(
  (icon) => IconsRegular[icon],
);
const iconListBrands = Object.keys(IconsBrands.fab).map(
  (icon) => IconsBrands[icon],
);

library.add(...iconList, ...iconListRegular, ...iconListBrands);

const additionalExpressMiddlewareServerConfig =
  typeof __SERVER__ !== 'undefined' && __SERVER__
    ? require('../express-middleware').default
    : false;

export default function applyConfig(config) {
  config = applyRichTextConfig(config);
  config = applyAccordionConfig(config);

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['it', 'en'],
    defaultLanguage: 'it',
    showTags: false,
    matomoSiteId: '9',
    matomoUrlBase: 'https://stats.plone.org/',
    verticalFormTabs: true,

    serverConfig: {
      ...config.settings.serverConfig,
      extractScripts: {
        ...config.settings.serverConfig.extractScripts,
        errorPages: true,
      },
    },
    defaultBlockType: 'text',
  };

  if (additionalExpressMiddlewareServerConfig) {
    config.settings.expressMiddleware = [
      ...config.settings.expressMiddleware,
      ...additionalExpressMiddlewareServerConfig,
    ];
  }

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2024-05-06T14:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
            },
          },
          // ...config.settings[
          //   'volto-gdpr-privacy'
          // ].defaultPanelConfig.technical.choices.filter(
          //   (f) => f.config_key !== 'GANALYTICS',
          // ),
        ],
      },
    },
  };

  SiteBlocks(config); //blocks configuration for this site
  SiteWidgets(config); //widgets configuration for this.site
  SiteViews(config); //views configuration for this.site

  return config;
}
